export const getTitle = () => {
  return `${getBaseName()} LMS`;
};

const getBaseName = () => {
  return process.env.REACT_APP_BASENAME || "";
};

export const getBaseURI = () => {
  return process.env.REACT_APP_API_URL || "";
};

export const getBackgroundImage = () => {
  return process.env.REACT_APP_BACKGROUND_IMAGE || "";
}