import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import styled from "@mui/material/styles/styled";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import DoneIcon from "@mui/icons-material/Done";
import { TableFooter } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { LightTooltip } from "../../components/LightToolTip";
import { useAdminFetch } from "../../api/fetch";
import { StyledTextField } from "../../components/StyledTextField";
import { StyledFormControl } from "../../components/StyledFormControl";

const LogDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "1000px",
    maxHeight: "500px",
    width: "100%",
    height: "100%",
    borderRadius: "20px",
  },
}));

const UserAddDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "500px",
    maxHeight: "550px",
    width: "100%",
    height: "100%",
    borderRadius: "20px",
  },
}));

export default function UserList() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageLog, setPageLog] = useState(0);
  const [rowsPerPageLog, setRowsPerPageLog] = useState(5);
  const [lectureData, setLectureData] = useState([]);

  const { get, post } = useAdminFetch();

  const [data, setData] = useState([]);

  const refresh = useCallback(async () => {
    const result = await get<{
      message: string;
      users: any;
      lectures: any;
    }>(`/api/admin/users`);
    console.log(result.message);
    setData(result.users);
    setLectureData(result.lectures);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refresh();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [openLog, setOpenLog] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [log, setLog] = useState([]);

  const handleClickOpenLog = async (
    id: string,
    userName: string,
    email: string
  ) => {
    setOpenLog(true);
    setUserName(userName);
    setUserEmail(email);
    try {
      const logs = await logData(id);
      if (logs) {
        setLog(logs);
      } else {
        setLog([]);
      }
    } catch (error) {
      alert("Can't get log data from db properly.");
      setLog([]);
    }
  };

  const handleClickCloseLog = () => {
    setOpenLog(false);
    setOpenDetail({});
  };

  const logData = async (id: string) => {
    try {
      const response = await get<{
        log: any;
      }>(`/api/admin/user/` + id);

      return response.log;
    } catch (error) {
      alert("Can't get data from db properly.");
      return null;
    }
  };

  const [openLoginLogoutLog, setOpenLoginLogoutLog] = useState(false);
  const [loginLogoutLog, setLoginLogoutLog] = useState([]);

  const handleClickOpenLoginLogoutLog = async (
    id: string,
    userName: string,
    email: string
  ) => {
    setOpenLoginLogoutLog(true);
    setUserName(userName);
    setUserEmail(email);
    try {
      const loginLogoutLogs = await LoginLogOutLogData(id);
      if (loginLogoutLogs) {
        setLoginLogoutLog(loginLogoutLogs);
      } else {
        setLoginLogoutLog([]);
      }
    } catch (error) {
      alert("Can't get log data from db properly.");
      setLoginLogoutLog([]);
    }
  };

  const handleClickCloseLoginLogoutLog = () => {
    setOpenLoginLogoutLog(false);
  };

  const LoginLogOutLogData = async (id: string) => {
    try {
      const response = await get<{
        log: any;
      }>(`/api/admin/user/login-logout/` + id);

      return response.log;
    } catch (error) {
      alert("Can't get data from db properly.");
      return null;
    }
  };

  const tbody = data ? (
    data
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row: any) => {
        const lectureNames = row.lectures
          .map((lecture: any) => lecture.name)
          .join(", ");
        return (
          <TableRow key={row.id}>
            <TableCell align="center" sx={{ width: "20%" }}>
              {row.userName}
            </TableCell>
            <TableCell align="center" sx={{ width: "20%" }}>
              {row.email}
            </TableCell>
            <TableCell align="center" sx={{ width: "30%" }}>
              {lectureNames}
            </TableCell>
            <TableCell align="center" sx={{ width: "15%" }}>
              <Button
                variant="contained"
                onClick={() =>
                  handleClickOpenLog(row.id, row.userName, row.email)
                }
                sx={{
                  borderRadius: "20px",
                  backgroundColor: "#80c3c6",
                  "&:hover": {
                    backgroundColor: "#80c3c6",
                  },
                }}
              >
                <TaskOutlinedIcon />
              </Button>
            </TableCell>
            <TableCell align="center" sx={{ width: "15%" }}>
              <Button
                variant="contained"
                onClick={() =>
                  handleClickOpenLoginLogoutLog(row.id, row.userName, row.email)
                }
                sx={{
                  borderRadius: "20px",
                  backgroundColor: "#80c3c6",
                  "&:hover": {
                    backgroundColor: "#80c3c6",
                  },
                }}
              >
                <AccessTimeIcon />
              </Button>
            </TableCell>
          </TableRow>
        );
      })
  ) : (
    <TableRow>
      <TableCell align="center" colSpan={4}>
        データがありません
      </TableCell>
    </TableRow>
  );

  const [openDetail, setOpenDetail] = useState<any>({});

  const toggleDetail = (lectureId: string) => {
    setOpenDetail((prevState: { [x: string]: any }) => ({
      ...prevState,
      [lectureId]: !prevState[lectureId],
    }));
  };

  function formatDate(dateString: string | number | Date) {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
    const day = ("0" + date.getUTCDate()).slice(-2);
    const hours = ("0" + date.getUTCHours()).slice(-2);
    const minutes = ("0" + date.getUTCMinutes()).slice(-2);

    return `${year}/${month}/${day} ${hours}:${minutes}`;
  }

  const logtbody = log ? (
    log
      .slice(
        pageLog * rowsPerPageLog,
        pageLog * rowsPerPageLog + rowsPerPageLog
      )
      .map((row: any) => {
        const numberOfVideos = row.videos.length;
        const completedVideos = row.videos.filter(
          (video: { videoStatement: boolean }) => video.videoStatement === true
        ).length;
        const completedTests = row.videos.filter(
          (video: { testContent: string }) => video.testContent !== "-"
        ).length;
        return (
          <React.Fragment key={row.id}>
            <TableRow style={{ backgroundColor: "#f5fafa" }}>
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => toggleDetail(row.id)}
                  sx={{ width: "10%" }}
                >
                  {openDetail[row.id] ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </TableCell>
              <TableCell align="center" sx={{ width: "40%" }}>
                <Typography variant="body2" fontWeight="bold">
                  {row.lectureName}
                </Typography>
              </TableCell>
              <TableCell align="center" sx={{ width: "25%" }}>
                <Typography variant="body2" fontWeight="bold">
                  {numberOfVideos !== 0
                    ? Math.round((completedVideos * 100) / numberOfVideos)
                    : 0}{" "}
                  %{" "}
                </Typography>
              </TableCell>
              <TableCell align="center" sx={{ width: "25%" }}>
                <Typography variant="body2" fontWeight="bold">
                  {numberOfVideos !== 0
                    ? Math.round((completedTests * 100) / numberOfVideos)
                    : 0}{" "}
                  %{" "}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={6}
              >
                <Collapse in={openDetail[row.id]} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                    <Table size="small" aria-label="videos">
                      <TableHead style={{ backgroundColor: "#E5E4E2" }}>
                        <TableRow>
                          <TableCell align="center" sx={{ width: "30%" }}>
                            動画名
                          </TableCell>
                          <TableCell align="center" sx={{ width: "10%" }}>
                            視聴状況
                          </TableCell>
                          <TableCell align="center" sx={{ width: "60%" }}>
                            小テスト回答
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.videos.map(
                          (video: {
                            id: React.Key | null | undefined;
                            videoName:
                              | string
                              | number
                              | boolean
                              | React.ReactElement<
                                  any,
                                  string | React.JSXElementConstructor<any>
                                >
                              | Iterable<React.ReactNode>
                              | React.ReactPortal
                              | null
                              | undefined;
                            videoStartTime: string;
                            videoEndTime: string;
                            videoStatement: any;
                            testContent: string;
                            testTime: any;
                          }) => (
                            <TableRow key={video.id}>
                              <TableCell align="center" sx={{ width: "30%" }}>
                                <Typography variant="body2" fontWeight="bold">
                                  {video.videoName}
                                </Typography>
                              </TableCell>
                              <TableCell align="center" sx={{ width: "10%" }}>
                                <Typography variant="body2">
                                  <LightTooltip
                                    title={
                                      <React.Fragment>
                                        開始時刻:{" "}
                                        {video.videoStartTime !== "-"
                                          ? formatDate(video.videoStartTime)
                                          : "-"}{" "}
                                        <br />
                                        終了時刻:{" "}
                                        {video.videoEndTime !== "-"
                                          ? formatDate(video.videoEndTime)
                                          : "-"}
                                      </React.Fragment>
                                    }
                                    placement="right"
                                  >
                                    <span>
                                      {video.videoStatement ? (
                                        <DoneIcon />
                                      ) : (
                                        "-"
                                      )}
                                    </span>
                                  </LightTooltip>
                                </Typography>
                              </TableCell>
                              <TableCell align="center" sx={{ width: "60%" }}>
                                <Typography variant="body2">
                                  {video.testContent !== "-"
                                    ? video.testContent +
                                      "(作成日：" +
                                      formatDate(video.testTime) +
                                      ")"
                                    : "-"}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      })
  ) : (
    <TableRow>
      <TableCell align="center" colSpan={4}>
        データがありません
      </TableCell>
    </TableRow>
  );

  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePageLog = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPageLog(newPage);
  };

  const handleChangeRowsPerPageLog = (event: { target: { value: string } }) => {
    setRowsPerPageLog(parseInt(event.target.value, 10));
    setPageLog(0);
  };

  const [pageloginLogoutLog, setPageloginLogoutLog] = useState(0);
  const [rowsPerPageloginLogoutLog, setRowsPerPageloginLogoutLog] = useState(5);

  const handleChangePageloginLogoutLog = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPageloginLogoutLog(newPage);
  };

  const handleChangeRowsPerPageloginLogoutLog = (event: {
    target: { value: string };
  }) => {
    setRowsPerPageloginLogoutLog(parseInt(event.target.value, 10));
    setPageloginLogoutLog(0);
  };

  const [openUserAddDialog, setOpenUserAddDialog] = useState(false);
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [email, setEmail] = useState("");
  const [selectId, setSelectId] = useState<string[]>([]);
  const [createUserUserError, setCreateUserUserError] = useState("");
  const [createUserEmailError, setCreateUserEmailError] = useState("");
  const [createUserPasswordError, setCreateUserPasswordError] = useState("");
  const [createUserLectureError, setCreateUserLectureError] = useState("");
  const [createUserAlert, setCreateUserAlert] = useState(false);
  const [createUserErrorAlert, setCreateUserErrorAlert] = useState(false);

  const handleOpenUserAdd = () => {
    setOpenUserAddDialog(true);
  };

  const handleCloseUserAdd = () => {
    setOpenUserAddDialog(false);
    setCreateUserAlert(false);
    setCreateUserErrorAlert(false);
    setCreateUserUserError("");
    setCreateUserEmailError("");
    setCreateUserPasswordError("");
    setCreateUserLectureError("");
  };

  const handleAddUser = async (e: any) => {
    if (user !== "" && pass !== "" && email !== "" && selectId.length !== 0) {
      const result = await post<{
        success: boolean;
      } >(`/api/admin/user/create`, {
        body: JSON.stringify({
            user_from_react: user,
            email_from_react: email,
            pass_from_react: pass,
            select_lecture_from_react: selectId,
            }),
      });

      if (result.success === true) {
        setCreateUserAlert(true);
        setUser("");
        setPass("");
        setEmail("");
        setSelectId([]);
        refresh();
      } else {
        setCreateUserErrorAlert(true);
      }
    } else if (
      user === "" &&
      pass !== "" &&
      email !== "" &&
      selectId.length !== 0
    ) {
      setCreateUserUserError("ユーザー名が入力されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user === "" &&
      pass === "" &&
      email !== "" &&
      selectId.length !== 0
    ) {
      setCreateUserUserError("ユーザー名が入力されていません");
      setCreateUserPasswordError("パスワードが入力されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user === "" &&
      pass !== "" &&
      email === "" &&
      selectId.length !== 0
    ) {
      setCreateUserUserError("ユーザー名が入力されていません");
      setCreateUserEmailError("メールアドレスが入力されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user !== "" &&
      pass === "" &&
      email !== "" &&
      selectId.length !== 0
    ) {
      setCreateUserPasswordError("パスワードが入力されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user !== "" &&
      pass === "" &&
      email === "" &&
      selectId.length !== 0
    ) {
      setCreateUserPasswordError("パスワードが入力されていません");
      setCreateUserEmailError("メールアドレスが入力されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user !== "" &&
      pass !== "" &&
      email === "" &&
      selectId.length !== 0
    ) {
      setCreateUserEmailError("メールアドレスが入力されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user === "" &&
      pass !== "" &&
      email !== "" &&
      selectId.length === 0
    ) {
      setCreateUserUserError("ユーザー名が入力されていません");
      setCreateUserLectureError("講座が選択されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user === "" &&
      pass === "" &&
      email !== "" &&
      selectId.length === 0
    ) {
      setCreateUserUserError("ユーザー名が入力されていません");
      setCreateUserPasswordError("パスワードが入力されていません");
      setCreateUserLectureError("講座が選択されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user === "" &&
      pass !== "" &&
      email === "" &&
      selectId.length === 0
    ) {
      setCreateUserUserError("ユーザー名が入力されていません");
      setCreateUserEmailError("メールアドレスが入力されていません");
      setCreateUserLectureError("講座が選択されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user !== "" &&
      pass === "" &&
      email !== "" &&
      selectId.length === 0
    ) {
      setCreateUserPasswordError("パスワードが入力されていません");
      setCreateUserLectureError("講座が選択されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user !== "" &&
      pass === "" &&
      email === "" &&
      selectId.length === 0
    ) {
      setCreateUserPasswordError("パスワードが入力されていません");
      setCreateUserEmailError("メールアドレスが入力されていません");
      setCreateUserLectureError("講座が選択されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user !== "" &&
      pass !== "" &&
      email === "" &&
      selectId.length === 0
    ) {
      setCreateUserEmailError("メールアドレスが入力されていません");
      setCreateUserLectureError("講座が選択されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user !== "" &&
      pass !== "" &&
      email !== "" &&
      selectId.length === 0
    ) {
      setCreateUserLectureError("講座が選択されていません");
      setCreateUserErrorAlert(true);
    } else if (
      user === "" &&
      pass === "" &&
      email === "" &&
      selectId.length !== 0
    ) {
      setCreateUserUserError("ユーザー名が入力されていません");
      setCreateUserPasswordError("パスワードが入力されていません");
      setCreateUserEmailError("メールアドレスが入力されていません");
      setCreateUserErrorAlert(true);
    } else {
      setCreateUserUserError("ユーザー名が入力されていません");
      setCreateUserPasswordError("パスワードが入力されていません");
      setCreateUserEmailError("メールアドレスが入力されていません");
      setCreateUserLectureError("講座が選択されていません");
      setCreateUserErrorAlert(true);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 1200,
        bgcolor: "background.paper",
        mr: 4,
        ml: 4,
      }}
    >
      <Box sx={{ display: "flex", mt: 4, mb: 2 }}>
        <Typography
          sx={{ fontWeight: "bold", width: "85%" }}
          variant="h4"
          component="div"
        >
          受講者一覧
        </Typography>
        <Box sx={{ width: "15%" }}>
          <Button
            variant="contained"
            sx={{
              mr: 2,
              color: "white",
              backgroundColor: "#d7b27a",
              borderRadius: "20px",
              "&:hover": {
                backgroundColor: "#D7B27A",
                transform: "scale(1.025)",
              },
              fontWeight: "bold",
            }}
            onClick={handleOpenUserAdd}
          >
            <PersonAddAltOutlinedIcon sx={{ mr: 1 }} />
            受講者追加
          </Button>
        </Box>
      </Box>
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ borderRadius: "20px" }}
      >
        <Table
          sx={{
            minWidth: 300,
            [`& .${tableCellClasses.root}`]: {
              borderLeft: "none",
              borderRight: "none",
            },
          }}
          aria-label="simple table"
        >
          <TableHead style={{ backgroundColor: "#E5E4E2" }}>
            <TableRow>
              <TableCell align="center" sx={{ width: "20%" }}>
                ユーザー名
              </TableCell>
              <TableCell align="center" sx={{ width: "20%" }}>
                メールアドレス
              </TableCell>
              <TableCell align="center" sx={{ width: "30%" }}>
                登録講座
              </TableCell>
              <TableCell align="center" sx={{ width: "15%" }}>
                受講履歴
              </TableCell>
              <TableCell align="center" sx={{ width: "15%", fontSize: "12px" }}>
                ログイン/ログアウト履歴
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tbody}</TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                labelRowsPerPage="表示件数:"
                rowsPerPageOptions={[
                  { label: "10件", value: 10 },
                  { label: "50件", value: 50 },
                  { label: "100件", value: 100 },
                  { label: "全て", value: data ? data.length : 0 },
                ]}
                count={data ? data.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <LogDialog
        onClose={handleClickCloseLog}
        aria-labelledby="log-dialog"
        open={openLog}
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>
          ユーザ名：{userName}（メールアドレス：{userEmail}）
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClickCloseLog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ overflowY: "auto" }}>
          <TableContainer
            component={Paper}
            variant="outlined"
            sx={{ borderRadius: "20px" }}
          >
            <Table
              sx={{
                minWidth: 300,
                [`& .${tableCellClasses.root}`]: {
                  borderLeft: "none",
                  borderRight: "none",
                },
              }}
              aria-label="simple table"
            >
              <TableHead sx={{ backgroundColor: "#80c3c6" }}>
                <TableRow>
                  <TableCell align="center" sx={{ width: "10%" }} />
                  <TableCell align="center" sx={{ width: "40%" }}>
                    講座名
                  </TableCell>
                  <TableCell align="center" sx={{ width: "25%" }}>
                    動画視聴状況
                  </TableCell>
                  <TableCell align="center" sx={{ width: "25%" }}>
                    小テスト回答状況
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{logtbody}</TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    labelRowsPerPage="表示件数:"
                    rowsPerPageOptions={[
                      { label: "5件", value: 5 },
                      { label: "10件", value: 10 },
                      { label: "50件", value: 50 },
                      { label: "全て", value: log ? log.length : 0 },
                    ]}
                    count={log ? log.length : 0}
                    rowsPerPage={rowsPerPageLog}
                    page={pageLog}
                    onPageChange={handleChangePageLog}
                    onRowsPerPageChange={handleChangeRowsPerPageLog}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </DialogContent>
      </LogDialog>
      <LogDialog
        onClose={handleClickCloseLoginLogoutLog}
        aria-labelledby="log-dialog"
        open={openLoginLogoutLog}
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>
          ユーザ名：{userName}（メールアドレス：{userEmail}）
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClickCloseLoginLogoutLog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ overflowY: "auto" }}>
          <TableContainer
            component={Paper}
            variant="outlined"
            sx={{ borderRadius: "20px" }}
          >
            <Table
              sx={{
                minWidth: 300,
                [`& .${tableCellClasses.root}`]: {
                  borderLeft: "none",
                  borderRight: "none",
                },
              }}
              aria-label="simple table"
            >
              <TableHead sx={{ backgroundColor: "#80c3c6" }}>
                <TableRow>
                  <TableCell align="center" sx={{ width: "20%" }}>
                    ログイン日時
                  </TableCell>
                  <TableCell align="center" sx={{ width: "20%" }}>
                    ログアウト日時
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loginLogoutLog
                  .slice(
                    pageloginLogoutLog * rowsPerPageloginLogoutLog,
                    pageloginLogoutLog * rowsPerPageloginLogoutLog +
                      rowsPerPageloginLogoutLog
                  )
                  .map((row: any) => (
                    <TableRow key={row.id}>
                      <TableCell align="center" sx={{ width: "20%" }}>
                        {formatDate(row.loginTime)}
                      </TableCell>
                      <TableCell align="center" sx={{ width: "20%" }}>
                        {row.logoutTime !== null
                          ? formatDate(row.logoutTime)
                          : "-"}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    labelRowsPerPage="表示件数:"
                    rowsPerPageOptions={[
                      { label: "5件", value: 5 },
                      { label: "10件", value: 10 },
                      { label: "50件", value: 50 },
                      {
                        label: "全て",
                        value: loginLogoutLog ? loginLogoutLog.length : 0,
                      },
                    ]}
                    count={loginLogoutLog ? loginLogoutLog.length : 0}
                    rowsPerPage={rowsPerPageloginLogoutLog}
                    page={pageloginLogoutLog}
                    onPageChange={handleChangePageloginLogoutLog}
                    onRowsPerPageChange={handleChangeRowsPerPageloginLogoutLog}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </DialogContent>
      </LogDialog>
      <UserAddDialog
        open={openUserAddDialog}
        aria-labelledby="user-add-dialog"
        onClose={handleCloseUserAdd}
      >
        <DialogTitle
          sx={{
            backgroundColor: "#d7b27a",
            color: "white",
            fontWeight: "bold",
            fontSize: "23px",
          }}
          id="user-add-dialog-title"
          align="center"
        >
          <PersonAddAltOutlinedIcon
            fontSize="large"
            sx={{ verticalAlign: "middle", mr: 1 }}
          />
          受講者追加
        </DialogTitle>
        <IconButton
          aria-label="close-user-add-dialog"
          onClick={handleCloseUserAdd}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <StyledTextField
            label="ユーザー名"
            variant="standard"
            onChange={(e) => {
              setUser(e.target.value);
              setCreateUserUserError("");
            }}
            value={user}
            sx={{ m: 1, minWidth: 250 }}
            required
            error={Boolean(createUserUserError)}
            helperText={createUserUserError}
          />
          <StyledTextField
            label="メールアドレス"
            type="email"
            variant="standard"
            onChange={(e) => {
              setEmail(e.target.value);
              setCreateUserEmailError("");
            }}
            value={email}
            sx={{ m: 1, minWidth: 250 }}
            required
            error={Boolean(createUserEmailError)}
            helperText={createUserEmailError}
          />
          <StyledTextField
            label="パスワード"
            type="password"
            variant="standard"
            onChange={(e) => {
              setPass(e.target.value);
              setCreateUserPasswordError("");
            }}
            value={pass}
            sx={{ m: 1, minWidth: 250 }}
            required
            error={Boolean(createUserPasswordError)}
            helperText={createUserPasswordError}
          />
          <StyledFormControl
            variant="standard"
            sx={{ m: 1, minWidth: 250 }}
            required
            error={Boolean(createUserLectureError)}
          >
            <InputLabel>登録する講座</InputLabel>
            <Select
              multiple
              value={selectId}
              onChange={(e) => {
                const {
                  target: { value },
                } = e;
                setSelectId(
                  typeof value === "string" ? value.split(",") : value
                );
                setCreateUserLectureError("");
              }}
              renderValue={(selected) => `${selected.length}個選択されました`}
            >
              {lectureData
                ? lectureData.map((row: any) => (
                    <MenuItem key={row.id} value={row.id}>
                      <Checkbox
                        checked={(selectId as any[]).indexOf(row.id) > -1}
                      />
                      <ListItemText primary={row.lectureName} />
                    </MenuItem>
                  ))
                : null}
            </Select>
            <FormHelperText id="user-lecture-error">
              {createUserLectureError}
            </FormHelperText>
          </StyledFormControl>
          <Button
            variant="contained"
            onClick={handleAddUser}
            sx={{
              width: "50%",
              color: "#d7b27a",
              alignSelf: "center",
              backgroundColor: "white",
              borderRadius: "16px",
              border: "2px solid #d7b27a",
              fontSize: "15px",
              "&:hover": {
                color: "white",
                transform: "scale(1.1)",
                backgroundColor: "#d7b27a",
              },
            }}
          >
            追加
          </Button>
        </DialogContent>
      </UserAddDialog>
      <Snackbar open={createUserAlert}>
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setCreateUserAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mr: 4, ml: 4 }}
        >
          受講者が正常に追加されました。
        </Alert>
      </Snackbar>
      <Snackbar open={createUserErrorAlert}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setCreateUserErrorAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mr: 4, ml: 4 }}
        >
          受講者を追加するのに失敗しました。
        </Alert>
      </Snackbar>
    </Box>
  );
}
