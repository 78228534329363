import { BaseContextType } from "..";
import { postInternal } from "./fetch";

export default async function logout(setSession: BaseContextType) {

  localStorage.removeItem("admin_token");
  const token = localStorage.getItem("token");
  if (token) {
    const time = new Date();
    const japanTime = new Date(time.getTime() + 9 * 60 * 60 * 1000);
    const logout_time = japanTime.toISOString();

    try {
      // 記録として必要
      await postInternal(
        `/api/user/logout`,
        { body: JSON.stringify({ logout_time }) },
        token
      );
    } catch (e) {
      console.log(e);
    }
  }

  localStorage.removeItem("token");
  setSession!({});
}
