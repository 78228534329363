import React from "react";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import Box from "@mui/material/Box";
import { motion } from "framer-motion";
import { getTitle, getBackgroundImage } from "../environment";
import { AdminLoginDialog, UserLoginDialog } from "../components/LoginDialog";

export default function Login() {
  const [openUser, setOpenUser] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          backgroundImage: getBackgroundImage(),
          margin: "50px 0px",
          padding: "80px 0px",
          textAlign: "center",
          width: "100%",
        }}
      >
        <motion.div
          initial={{ x: "-100vw" }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 120, duration: 1 }}
        >
          <Typography
            component="div"
            sx={{
              color: "white",
              fontWeight: "bold",
              fontSize: "150px",
              textDecoration: "underline",
            }}
          >
            {getTitle()}
          </Typography>
        </motion.div>
        <Typography
          component="div"
          sx={{
            color: "white",
            fontSize: "30px",
          }}
        >
          受講者専用ログインページ
        </Typography>
      </Box>
      <div style={{ margin: "10px" }}></div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1 }}
      >
        <Button
          variant="contained"
          onClick={() => setOpenUser(true)}
          sx={{
            color: "transparent",
            backgroundImage: getBackgroundImage(),
            backgroundClip: "text",
            border: "5px solid #c1c1a4",
            borderRadius: "48px",
            width: "300px",
            height: "75px",
            fontSize: "20px",
            "&:hover": {
              backgroundImage: getBackgroundImage(),
              color: "white",
              backgroundClip: "border-box",
              border: "5px solid white",
            },
          }}
        >
          ログインする
        </Button>
      </motion.div>
      <UserLoginDialog
        open={openUser}
        onClose={() => setOpenUser(false)}
        onChangeDialog={() => {
          setOpenUser(false);
          setOpenAdmin(true);
        }}
      />
      <AdminLoginDialog
        open={openAdmin}
        onClose={() => setOpenAdmin(false)}
        onChangeDialog={() => {
          setOpenAdmin(false);
          setOpenUser(true);
        }}
      />
    </Grid>
  );
}
