import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";

const disabledTime = 60; //1分

type TryLoginMessageProps = {
  attempt: number;
  setAttempt: React.Dispatch<React.SetStateAction<number>>;
};

const maxLoginAttempts = 5;

export const TryLoginMessage: React.FC<TryLoginMessageProps> = ({
  setAttempt,
  attempt,
}) => {
  const [count, setCount] = useState(0);
  const countdown = disabledTime - count;

  // countUp処理
  useEffect(() => {
    if (count > 0) {
      const timer = setTimeout(() => {
        setCount((prev) => prev + 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [count]);

  // countが終わったらdisabledを解除
  useEffect(() => {
    if (countdown <= 0) {
      setAttempt(0);
      setCount(0);
    }
  }, [countdown, setAttempt, setCount]);

  // ログイン試行回数がmaxLoginAttemptsを超えたらdisabled
  useEffect(() => {
    if (attempt >= maxLoginAttempts) {
      setCount(1);
    }
  }, [attempt]);

  if (attempt < maxLoginAttempts) {
    return (
      <Typography variant="body2" align="center">
        残りのログイン試行回数: {maxLoginAttempts - attempt} 回 /{" "}
        {maxLoginAttempts}回
      </Typography>
    );
  }

  const adminDisabledMessage = `次にアクセス可能な時間: ${Math.floor(
    countdown / 60
  )}分 ${countdown % 60}秒後`;

  return <Typography variant="body2">{adminDisabledMessage}</Typography>;
};
