import { useEffect, useState } from "react";
import { useAdminFetch } from "../../api/fetch";

export const useAdminName = () => {
  const [adminName, setAdminName] = useState<string | null>(null);
  const { get } = useAdminFetch();

  useEffect(() => {
    (async () => {
        const result = await get<{ adminName: string }>(
          `/api/admin/name`,
        );
        setAdminName(result.adminName);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return adminName;
};
