import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect, useCallback } from "react";
import { TableBody, TableFooter } from "@mui/material";
import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePagination from "@mui/material/TablePagination";
import Collapse from "@mui/material/Collapse";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import styled from "@mui/material/styles/styled";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import PersonIcon from "@mui/icons-material/Person";
import DownloadIcon from "@mui/icons-material/Download";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import ReactPlayer from "react-player";
import { CSVLink } from "react-csv";
import { StyledTextField } from "../../components/StyledTextField";
import { StyledFormControl } from "../../components/StyledFormControl";
import { useAdminFetch } from "../../api/fetch";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AddDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "500px",
    maxHeight: "550px",
    width: "100%",
    height: "100%",
    borderRadius: "20px",
  },
}));

const UnAttendedDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "1000px",
    maxHeight: "700px",
    width: "100%",
    height: "100%",
    borderRadius: "20px",
  },
}));

const VideoDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "1000px",
    maxHeight: "500px",
    width: "100%",
    height: "100%",
    borderRadius: "20px",
  },
}));

export default function LectureList() {
  const [lectureData, setLectureData] = useState<any[]>([]);
  const [unattendedLectureData, setUnattendedLectureData] = useState<any[]>([]);
  const [unattendedVideoData, setUnattendedVideoData] = useState<any[]>([]);
  const { get, post, put } = useAdminFetch();

  const refresh = useCallback(async () => {
    get<{
      message: string;
      lectures: any[];
    }>(`/api/admin/lectures/videos`)
      .then((res) => {
        console.log(res.message);
        setLectureData(res.lectures);
      })
      .catch(() => {
        alert("Can't get data from db properly.");
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refresh();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDetail, setOpenDetail] = useState<any>({});
  const [openLectureUnattendedDialog, setOpenLectureUnattendedDialog] =
    useState(false);
  const [openVideoUnattendedDialog, setOpenVideoUnattendedDialog] =
    useState(false);
  const [lectureName, setLectureName] = useState("");
  const [lectureVideoName, setLectureVideoName] = useState("");
  const [pageLectureUnattended, setPageLectureUnattended] = useState(0);
  const [rowsPerPageLectureUnattended, setRowsPerPageLectureUnattended] =
    useState(5);
  const [pageVideoUnattended, setPageVideoUnattended] = useState(0);
  const [rowsPerPageVideoUnattended, setRowsPerPageVideoUnattended] =
    useState(5);
  const [openVideoDialog, setOpenVideoDialog] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [content, setContent] = useState<string | null>(null);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePageLectureUnattended = (event: any, newPage: any) => {
    setPageLectureUnattended(newPage);
  };

  const handleChangeRowsPerPageLectureUnattended = (event: {
    target: { value: string };
  }) => {
    setRowsPerPageLectureUnattended(parseInt(event.target.value, 10));
    setPageLectureUnattended(0);
  };

  const handleChangePageVideoUnattended = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPageVideoUnattended(newPage);
  };

  const handleChangeRowsPerPageVideoUnattended = (event: {
    target: { value: string };
  }) => {
    setRowsPerPageVideoUnattended(parseInt(event.target.value, 10));
    setPageVideoUnattended(0);
  };

  const toggleDetail = (lectureId: string | number) => {
    setOpenDetail((prevState: { [x: string]: any }) => ({
      ...prevState,
      [lectureId]: !prevState[lectureId],
    }));
  };

  const handleOpenLectureUnattendedDialog = (
    id: any,
    lectureName: React.SetStateAction<string>
  ) => {
    setOpenLectureUnattendedDialog(true);
    setLectureName(lectureName);
    getUnattendedLectureData(id);
  };

  const handleCloseLectureUnattendedDialog = () => {
    setOpenLectureUnattendedDialog(false);
  };

  const handleOpenVideoUnattendedDialog = (
    id: any,
    lectureName: React.SetStateAction<string>,
    lectureVideoName: React.SetStateAction<string>
  ) => {
    setOpenVideoUnattendedDialog(true);
    setLectureName(lectureName);
    setLectureVideoName(lectureVideoName);
    getUnattendedVideoData(id);
  };

  const handleCloseVideoUnattendedDialog = () => {
    setOpenVideoUnattendedDialog(false);
  };

  const handleOpenVideoDialog = (content: string, fileName: any) => {
    setContent(content);
    getVideo(fileName);
    setOpenVideoDialog(true);
  };

  const handleCloseVideoDialog = () => {
    setOpenVideoDialog(false);
    setVideoUrl("");
  };

  const onErrorTime = (error: any) => {
    if (error.code === 2) {
      alert("動画のセッションが切れました。");
      handleCloseVideoDialog();
    }
    const time = new Date();
    const japanTime = new Date(time.getTime() + 9 * 60 * 60 * 1000);
    const errorTime = japanTime.toISOString();
    console.log("Error has Occured  :" + errorTime);
    console.log("Error Code  :" + error.code);
    console.log("Error" + error);
  };

  const getVideo = (fileName: string) => {
    get<{
      url: string;
    }>(`/api/admin/video/` + fileName)
      .then((res) => {
        console.log(res.url);
        setVideoUrl(res.url);
      })
      .catch(() => alert("Can't get Video from db properly."));
  };

  const getUnattendedLectureData = async (id: any) => {
    get<{
      message: string;
      unattendedLectureData: any[];
    }>(`/api/admin/lecture/unattended/${id}`)
      .then((res) => {
        console.log(res.message);
        setUnattendedLectureData(res.unattendedLectureData);
      })
      .catch(() => {
        alert("Can't get data from db properly.");
      });
  };

  const getUnattendedVideoData = async (id: any) => {
    get<{
      message: string;
      unattendedVideoData: any[];
    }>(`/api/admin/video/unattended/${id}`)
      .then((res) => {
        console.log(res.message);
        setUnattendedVideoData(res.unattendedVideoData);
      })
      .catch(() => {
        alert("Can't get data from db properly.");
      });
  };

  const tbody = lectureData ? (
    lectureData
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row: any) => {
        const videosNumber = row.videos.length;
        const handleDragEnd = (result: any) => {
          if (!result.destination) {
            return;
          }
          const items = Array.from(row.videos);
          const [reorderedItem] = items.splice(result.source.index, 1);
          items.splice(result.destination.index, 0, reorderedItem);
          const newLectureData = [...lectureData];
          (newLectureData as any).find(
            (lecture: any) => lecture.id === row.id
          ).videos = items;
          const videoOrder = items.map((video: any) => video.id);
          items.forEach((video: any, index) => {
            video.order = index + 1;
          });
          try {
            post<{
              message: string;
              success: boolean;
            }>(`/api/admin/video/update`, {
              body: JSON.stringify({
                lectureId: row.id,
                videoOrder: JSON.stringify(videoOrder),
              }),
            })
              .then((res) => {
                console.log(res.message);
                if (res.success) {
                  setLectureData(newLectureData);
                  setVideoOrderSuccess(true);
                } else {
                  setVideoOrderFail(true);
                }
              })
              .catch(() => {
                setVideoOrderFail(true);
              });
          } catch (error) {
            console.error("Error:", error);
            setVideoOrderFail(true);
          }
        };
        return (
          <React.Fragment key={row.id}>
            <TableRow>
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => toggleDetail(row.id)}
                  sx={{ width: "10%" }}
                >
                  {openDetail[row.id] ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </TableCell>
              <TableCell align="center" sx={{ width: "45%" }}>
                <Typography variant="body2" fontWeight="bold">
                  {row.lectureName}
                </Typography>
              </TableCell>
              <TableCell align="center" sx={{ width: "15%" }}>
                <Typography variant="body2" fontWeight="bold">
                  {videosNumber}
                </Typography>
              </TableCell>
              <TableCell align="center" sx={{ width: "15%" }}>
                <Typography variant="body2" fontWeight="bold">
                  {row.studentsNumber !== 0
                    ? row.attendedStudents + "/" + row.studentsNumber
                    : "-"}
                </Typography>
              </TableCell>
              <TableCell align="center" sx={{ width: "15%" }}>
                <Button
                  variant="contained"
                  sx={{
                    color: "white",
                    backgroundColor: "#80c3c6",
                    borderRadius: "20px",
                    "&:hover": {
                      backgroundColor: "#80c3c6",
                    },
                    fontWeight: "bold",
                  }}
                  onClick={() =>
                    handleOpenLectureUnattendedDialog(row.id, row.lectureName)
                  }
                >
                  <PersonIcon />
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={6}
              >
                <Collapse in={openDetail[row.id]} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId={`videos-${row.id}`}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            <Table
                              size="small"
                              aria-label="videos"
                              sx={{ border: "1px solid #E5E4E2" }}
                            >
                              <TableHead style={{ backgroundColor: "#E5E4E2" }}>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    sx={{ width: "10%" }}
                                  >
                                    順番
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{ width: "40%" }}
                                  >
                                    動画名
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{ width: "15%" }}
                                  >
                                    プレビュー
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{ width: "10%" }}
                                  >
                                    視聴状況
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{ width: "10%" }}
                                  >
                                    回答状況
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{ width: "15%" }}
                                  >
                                    未完了者一覧
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {row.videos.map((video: any, index: number) => (
                                  <Draggable
                                    key={video.id}
                                    draggableId={`video-${video.id}`}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <TableRow
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        key={video.id}
                                        sx={{ border: "1px solid #E5E4E2" }}
                                      >
                                        <TableCell
                                          align="center"
                                          sx={{ width: "10%" }}
                                        >
                                          <Typography
                                            variant="body2"
                                            fontWeight="bold"
                                          >
                                            {video.order}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          sx={{ width: "40%" }}
                                        >
                                          <Typography
                                            variant="body2"
                                            fontWeight="bold"
                                          >
                                            {video.videoName}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          sx={{ width: "15%" }}
                                        >
                                          <IconButton
                                            aria-label="play"
                                            size="large"
                                            onClick={() =>
                                              handleOpenVideoDialog(
                                                video.videoName,
                                                video.fileName
                                              )
                                            }
                                          >
                                            <PlayCircleIcon fontSize="medium" />
                                          </IconButton>
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          sx={{ width: "10%" }}
                                        >
                                          <Typography
                                            variant="body2"
                                            fontWeight="bold"
                                          >
                                            {row.studentsNumber !== 0
                                              ? video.watchedStudents +
                                                "/" +
                                                row.studentsNumber
                                              : "-"}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          sx={{ width: "10%" }}
                                        >
                                          <Typography
                                            variant="body2"
                                            fontWeight="bold"
                                          >
                                            {row.studentsNumber !== 0
                                              ? video.answeredStudents +
                                                "/" +
                                                row.studentsNumber
                                              : "-"}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          sx={{ width: "15%" }}
                                        >
                                          <Button
                                            variant="contained"
                                            sx={{
                                              color: "white",
                                              backgroundColor: "#80c3c6",
                                              borderRadius: "20px",
                                              "&:hover": {
                                                backgroundColor: "#80c3c6",
                                              },
                                              fontWeight: "bold",
                                            }}
                                            onClick={() =>
                                              handleOpenVideoUnattendedDialog(
                                                video.id,
                                                row.lectureName,
                                                video.videoName
                                              )
                                            }
                                          >
                                            <PersonIcon />
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </TableBody>
                            </Table>
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      })
  ) : (
    <TableRow>
      <TableCell align="center" colSpan={4}>
        データがありません
      </TableCell>
    </TableRow>
  );

  const [videoOrderSuccess, setVideoOrderSuccess] = useState(false);
  const [videoOrderFail, setVideoOrderFail] = useState(false);

  const [openLectureAddDialog, setOpenLectureAddDialog] = useState(false);
  const [textLecture, setTextLecture] = useState("");
  const [createLectureError, setCreateLectureError] = useState("");
  const [createLectureAlert, setCreateLectureAlert] = useState(false);
  const [createLectureErrorAlert, setCreateLectureErrorAlert] = useState(false);
  const [openVideoAddDialog, setOpenVideoAddDialog] = useState(false);
  const [id, setId] = useState("");
  const [text, setText] = useState("");
  const [file, setFile] = useState<any>("");
  const [videoName, setVideoName] = useState("");
  const [videoSize, setVideoSize] = useState("");
  const [createVideoLectureError, setCreateVideoLectureError] = useState("");
  const [createVideoVideoError, setCreateVideoVideoError] = useState("");
  const [createVideoAlert, setCreateVideoAlert] = useState(false);
  const [createVideoErrorAlert, setCreateVideoErrorAlert] = useState(false);
  const [csvHeaders, setCsvHeaders] = useState<any[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const csvLink = React.createRef<any>();

  const handleOpenLectureAdd = () => {
    setOpenLectureAddDialog(true);
  };

  const handleCloseLectureAdd = () => {
    setOpenLectureAddDialog(false);
    setTextLecture("");
  };

  const handleOpenVideoAdd = () => {
    setOpenVideoAddDialog(true);
  };

  const handleCloseVideoAdd = () => {
    setOpenVideoAddDialog(false);
    setId("");
    setText("");
    setFile("");
    setVideoName("");
    setVideoSize("");
  };

  const createLecture = async (e: any) => {
    if (textLecture !== "") {
      post<{
        message: string;
        success: string;
      }>(`/api/admin/lecture/create`, {
        body: JSON.stringify({
          text_lecture_from_react: textLecture,
        }),
      }).then((res) => {
        console.log(res.message);
        if (res.success === "lc1") {
          setCreateLectureAlert(true);
          setTextLecture("");
          refresh();
        } else if (res.success === "lc3") {
          setCreateLectureError("すでにその講座名は存在しています");
          setCreateLectureErrorAlert(true);
        } else {
          setCreateLectureErrorAlert(true);
        }
      });
    } else {
      setCreateLectureError("講座名が入力されていません");
      setCreateLectureErrorAlert(true);
    }
  };

  const createVideo = async (e: any) => {
    if (file !== null && text !== "" && id !== "") {
      post<{
        message: string;
        success: string;
        uploadUrl: string;
      }>(`/api/admin/video/create`, {
        body: JSON.stringify({
          text_from_react: text,
          id_from_react: id,
          file_type_from_react: file.type,
        }),
      }).then(async (res) => {
        console.log(res.message);
        if (res.success === "vc1") {
          const uploadResponse = await put<number>(res.uploadUrl, {
            body: file,
            headers: {
              "Content-Type": file.type,
            },
          });
          if (uploadResponse === 200) {
            setCreateVideoAlert(true);
            setCreateVideoErrorAlert(false);
            setId("");
            setText("");
            setFile(null);
            setVideoName("");
            setVideoSize("");
            refresh();
          } else {
            setCreateVideoErrorAlert(true);
          }
        } else if (res.success === "vc3") {
          setCreateVideoVideoError("すでにその動画名は存在しています");
          setCreateVideoErrorAlert(true);
        } else {
          setCreateVideoErrorAlert(true);
        }
      });
    } else if (file === null && text !== "" && id !== "") {
      setCreateVideoErrorAlert(true);
    } else if (file === null && text === "" && id !== "") {
      setCreateVideoVideoError("動画名が入力されていません");
      setCreateVideoErrorAlert(true);
    } else if (file === null && text !== "" && id === "") {
      setCreateVideoLectureError("講座が選択されていません");
      setCreateVideoErrorAlert(true);
    } else if (file !== null && text === "" && id !== "") {
      setCreateVideoVideoError("動画名が入力されていません");
      setCreateVideoErrorAlert(true);
    } else if (file !== null && text === "" && id === "") {
      setCreateVideoVideoError("動画名が入力されていません");
      setCreateVideoLectureError("講座が選択されていません");
      setCreateVideoErrorAlert(true);
    } else if (file !== null && text !== "" && id === "") {
      setCreateVideoLectureError("講座が選択されていません");
      setCreateVideoErrorAlert(true);
    } else {
      setCreateVideoVideoError("動画名が入力されていません");
      setCreateVideoLectureError("講座が選択されていません");
      setCreateVideoErrorAlert(true);
    }
  };

  const formatDate = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
    const day = ("0" + date.getUTCDate()).slice(-2);
    const hours = ("0" + date.getUTCHours()).slice(-2);
    const minutes = ("0" + date.getUTCMinutes()).slice(-2);

    return `${year}/${month}/${day} ${hours}:${minutes}`;
  };

  const convertToCSV = (data: any[]) => {
    let jsonData: { email: any; lectureName: any; lectureStatus: any }[] = [];
    let maxVideosLength = 0;
    data.forEach((item: { videos: string | any[] }) => {
      if (item.videos.length > maxVideosLength) {
        maxVideosLength = item.videos.length;
      }
    });
    let headers = [
      { label: "メールアドレス", key: "email" },
      { label: "講座名", key: "lectureName" },
      { label: "講座状況", key: "lectureStatus" },
    ];
    for (let i = 0; i < maxVideosLength; i++) {
      headers.push({ label: `動画${i + 1}名`, key: `video${i + 1}_name` });
      headers.push({
        label: `動画${i + 1}視聴開始時刻`,
        key: `video${i + 1}_startTime`,
      });
      headers.push({
        label: `動画${i + 1}視聴終了時刻`,
        key: `video${i + 1}_endTime`,
      });
      headers.push({
        label: `動画${i + 1}テスト結果`,
        key: `video${i + 1}_testResult`,
      });
      headers.push({
        label: `動画${i + 1}テスト回答時刻`,
        key: `video${i + 1}_testTime`,
      });
    }
    data.forEach(
      (item: {
        email: any;
        lectureName: any;
        lectureStatus: any;
        videos: any[];
      }) => {
        let jsonItem: any = {
          email: item.email,
          lectureName: item.lectureName,
          lectureStatus: item.lectureStatus,
        };
        item.videos.forEach((video: any, index: number) => {
          jsonItem[`video${index + 1}_name`] = video.name;
          jsonItem[`video${index + 1}_startTime`] =
            video.startTime !== "-" ? formatDate(video.startTime) : "-";
          jsonItem[`video${index + 1}_endTime`] =
            video.endTime !== "-" ? formatDate(video.endTime) : "-";
          jsonItem[`video${index + 1}_testResult`] = video.testResult;
          jsonItem[`video${index + 1}_testTime`] =
            video.testTime !== "-" ? formatDate(video.testTime) : "-";
        });
        jsonData.push(jsonItem);
      }
    );
    return { jsonData, headers };
  };

  const downloadCSV = async () => {
    await get<{
      downloadData: any[];
    }>(`/api/admin/csv`)
      .then((res) => {
        const { jsonData, headers } = convertToCSV(res.downloadData);
        setCsvHeaders(headers);
        setCsvData(jsonData);
      })
      .catch(() => alert("Can't get csv download from db properly."));
  };

  useEffect(() => {
    //受講者がいないとcsvダウンロードができない
    if (csvHeaders.length > 0 && csvData.length > 0) {
      csvLink.current?.link?.click();
    }
  }, [csvHeaders, csvData, csvLink]);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 1000,
        bgcolor: "background.paper",
        mr: 4,
        ml: 4,
      }}
    >
      <Box sx={{ display: "flex", mt: 4, mb: 2 }}>
        <Typography
          sx={{ fontWeight: "bold", width: "50%" }}
          variant="h4"
          component="div"
        >
          講座一覧
        </Typography>
        <Box sx={{ width: "50%" }}>
          <Button
            variant="contained"
            sx={{
              mr: 2,
              color: "white",
              backgroundColor: "#d7b27a",
              borderRadius: "20px",
              "&:hover": {
                backgroundColor: "#D7B27A",
                transform: "scale(1.025)",
              },
              fontWeight: "bold",
            }}
            onClick={handleOpenLectureAdd}
          >
            <CreateNewFolderIcon sx={{ mr: 1 }} />
            講座追加
          </Button>
          <Button
            variant="contained"
            sx={{
              mr: 2,
              color: "white",
              backgroundColor: "#d7b27a",
              borderRadius: "20px",
              "&:hover": {
                backgroundColor: "#D7B27A",
                transform: "scale(1.025)",
              },
              fontWeight: "bold",
            }}
            onClick={handleOpenVideoAdd}
          >
            <PostAddIcon sx={{ mr: 1 }} />
            動画追加
          </Button>
          <Button
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: "#d7b27a",
              borderRadius: "20px",
              "&:hover": {
                backgroundColor: "#D7B27A",
                transform: "scale(1.025)",
              },
              fontWeight: "bold",
            }}
            onClick={() => downloadCSV()}
          >
            <DownloadIcon sx={{ mr: 1 }} />
            csvダウンロード
          </Button>
          <CSVLink
            headers={csvHeaders}
            data={csvData}
            filename={"user_logs.csv"}
            target="_blank"
            ref={csvLink}
            className="hidden"
          />
        </Box>
      </Box>
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ borderRadius: "20px" }}
      >
        <Table
          sx={{
            minWidth: 300,
            [`& .${tableCellClasses.root}`]: {
              borderLeft: "none",
              borderRight: "none",
            },
          }}
          aria-label="simple table"
        >
          <TableHead sx={{ backgroundColor: "#E5E4E2" }}>
            <TableRow>
              <TableCell align="center" sx={{ width: "10%" }} />
              <TableCell align="center" sx={{ width: "45%" }}>
                講座名
              </TableCell>
              <TableCell align="center" sx={{ width: "15%" }}>
                動画数
              </TableCell>
              <TableCell align="center" sx={{ width: "15%" }}>
                受講状況
              </TableCell>
              <TableCell align="center" sx={{ width: "15%" }}>
                未完了者一覧
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tbody}</TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                labelRowsPerPage="表示件数:"
                rowsPerPageOptions={[
                  { label: "10件", value: 10 },
                  { label: "50件", value: 50 },
                  {
                    label: "全て",
                    value: lectureData ? lectureData.length : 0,
                  },
                ]}
                count={lectureData ? lectureData.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Card
        variant="outlined"
        sx={{
          mt: 2,
          width: "100%",
          backgroundColor: "#E5E4E2",
          borderRadius: "20px",
        }}
      >
        <CardContent>
          <Typography sx={{ mb: 2 }} variant="h5" component="div">
            【備考】
          </Typography>
          <Typography sx={{ mb: 2 }} component="div">
            ・各講座内の動画の順番はドラッグ&ドロップで変更することができます。
          </Typography>
        </CardContent>
      </Card>
      <Snackbar
        open={videoOrderSuccess}
        autoHideDuration={2000}
        onClose={() => setVideoOrderSuccess(false)}
      >
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setVideoOrderSuccess(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mr: 4, ml: 4 }}
        >
          動画の順番が正常に変更されました。
        </Alert>
      </Snackbar>
      <Snackbar
        open={videoOrderFail}
        autoHideDuration={2000}
        onClose={() => setVideoOrderFail(false)}
      >
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setVideoOrderFail(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mr: 4, ml: 4 }}
        >
          動画の順番を変更できませんでした。
        </Alert>
      </Snackbar>
      <AddDialog
        open={openLectureAddDialog}
        onClose={handleCloseLectureAdd}
        aria-labelledby="lecture-add-dialog"
      >
        <DialogTitle
          sx={{
            backgroundColor: "#d7b27a",
            color: "white",
            fontWeight: "bold",
            fontSize: "23px",
          }}
          id="lecture-add-dialog-title"
          align="center"
        >
          <CreateNewFolderIcon
            fontSize="large"
            sx={{ verticalAlign: "middle", mr: 1 }}
          />
          講座追加
        </DialogTitle>
        <IconButton
          aria-label="close-lecture-add-dialog"
          onClick={handleCloseLectureAdd}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <StyledTextField
            label="講座名"
            variant="standard"
            onChange={(e) => {
              setTextLecture(e.target.value);
              setCreateLectureError("");
            }}
            value={textLecture}
            sx={{ m: 1, minWidth: 250 }}
            required
            error={Boolean(createLectureError)}
            helperText={createLectureError}
          />
          <Button
            variant="contained"
            onClick={createLecture}
            sx={{
              width: "50%",
              color: "#d7b27a",
              alignSelf: "center",
              backgroundColor: "white",
              borderRadius: "16px",
              border: "2px solid #d7b27a",
              fontSize: "15px",
              "&:hover": {
                color: "white",
                transform: "scale(1.1)",
                backgroundColor: "#d7b27a",
              },
            }}
          >
            追加する
          </Button>
        </DialogContent>
      </AddDialog>
      <Snackbar open={createLectureAlert}>
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setCreateLectureAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mr: 4, ml: 4 }}
        >
          講座が正常に追加されました。
        </Alert>
      </Snackbar>
      <Snackbar open={createLectureErrorAlert}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setCreateLectureErrorAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mr: 4, ml: 4 }}
        >
          講座を追加するのに失敗しました。
        </Alert>
      </Snackbar>
      <AddDialog
        open={openVideoAddDialog}
        onClose={handleCloseVideoAdd}
        aria-labelledby="video-add-dialog"
      >
        <DialogTitle
          sx={{
            backgroundColor: "#d7b27a",
            color: "white",
            fontWeight: "bold",
            fontSize: "23px",
          }}
          id="video-add-dialog-title"
          align="center"
        >
          <PostAddIcon
            fontSize="large"
            sx={{ verticalAlign: "middle", mr: 1 }}
          />
          動画追加
        </DialogTitle>
        <IconButton
          aria-label="close-video-add-dialog"
          onClick={handleCloseVideoAdd}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <StyledFormControl
            variant="standard"
            sx={{ m: 1, minWidth: 250 }}
            required
            error={Boolean(createVideoLectureError)}
          >
            <InputLabel>講座名</InputLabel>
            <Select
              value={id}
              onChange={(e) => {
                setId(e.target.value);
                setCreateVideoLectureError("");
              }}
            >
              {lectureData
                ? lectureData.map((row: any) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.lectureName}
                    </MenuItem>
                  ))
                : null}
            </Select>
            <FormHelperText id="video-lecture-error">
              {createVideoLectureError}
            </FormHelperText>
          </StyledFormControl>
          <StyledTextField
            label="動画名"
            variant="standard"
            onChange={(e) => {
              setText(e.target.value);
              setCreateVideoVideoError("");
            }}
            sx={{ m: 1, minWidth: 250 }}
            value={text}
            required
            error={Boolean(createVideoVideoError)}
            helperText={createVideoVideoError}
          />
          <Button
            sx={{
              mt: 2,
              borderRadius: "20px",
              width: "50%",
              alignSelf: "center",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            動画をアップロードする
            <VisuallyHiddenInput
              type="file"
              onChange={(e: any) => {
                const selectedFile = e.target.files[0];
                setFile(selectedFile);
                setVideoName(selectedFile.name);
                setVideoSize(Math.floor(e.target.files[0].size / 1024) + "kb");
              }}
            />
          </Button>
          <Typography
            component="div"
            sx={{
              alignSelf: "center",
            }}
          >
            {videoName !== "" && Number(videoSize) !== 0
              ? "動画名：" + videoName + "(大きさ：" + videoSize + ")"
              : "(動画がアップロードされていません)"}
          </Typography>
          <Button
            variant="contained"
            onClick={createVideo}
            sx={{
              width: "50%",
              color: "#d7b27a",
              alignSelf: "center",
              backgroundColor: "white",
              borderRadius: "16px",
              border: "2px solid #d7b27a",
              fontSize: "15px",
              "&:hover": {
                color: "white",
                transform: "scale(1.1)",
                backgroundColor: "#d7b27a",
              },
            }}
          >
            提出する
          </Button>
        </DialogContent>
      </AddDialog>
      <Snackbar open={createVideoAlert}>
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setCreateVideoAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mr: 4, ml: 4 }}
        >
          動画が正常に追加されました。
        </Alert>
      </Snackbar>
      <Snackbar open={createVideoErrorAlert}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setCreateVideoErrorAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mr: 4, ml: 4 }}
        >
          動画を追加するのに失敗しました。
        </Alert>
      </Snackbar>
      <UnAttendedDialog
        open={openLectureUnattendedDialog}
        onClose={handleCloseLectureUnattendedDialog}
        aria-labelledby="lecture-unattended-dialog"
      >
        <DialogTitle
          sx={{
            backgroundColor: "#d7b27a",
            color: "white",
            fontWeight: "bold",
            fontSize: "23px",
          }}
          id="lecture-unattended-dialog-title"
          align="center"
        >
          <PersonIcon
            fontSize="large"
            sx={{ verticalAlign: "middle", mr: 1 }}
          />
          講座未完了者一覧
          <br />
          〜講座名:『{lectureName}』〜
        </DialogTitle>
        <IconButton
          aria-label="close-lecture-unattended-dialog"
          onClick={handleCloseLectureUnattendedDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <TableContainer
            component={Paper}
            variant="outlined"
            sx={{ borderRadius: "20px" }}
          >
            <Table
              sx={{
                minWidth: 300,
                [`& .${tableCellClasses.root}`]: {
                  borderLeft: "none",
                  borderRight: "none",
                },
              }}
              aria-label="simple table"
            >
              <TableHead sx={{ backgroundColor: "#E5E4E2" }}>
                <TableRow>
                  <TableCell align="center" sx={{ width: "30%" }}>
                    メールアドレス
                  </TableCell>
                  <TableCell align="center" sx={{ width: "70%" }}>
                    未完了動画
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {unattendedLectureData ? (
                  unattendedLectureData
                    .slice(
                      pageLectureUnattended * rowsPerPageLectureUnattended,
                      pageLectureUnattended * rowsPerPageLectureUnattended +
                        rowsPerPageLectureUnattended
                    )
                    .map((row: any) => (
                      <TableRow key={row.id}>
                        <TableCell align="center" sx={{ width: "30%" }}>
                          <Typography variant="body2" fontWeight="bold">
                            {row.email}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: "70%" }}>
                          <Typography variant="body2" fontWeight="bold">
                            {row.videos ? row.videos : "動画を追加してください"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={4}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    labelRowsPerPage="表示件数:"
                    rowsPerPageOptions={[
                      { label: "5件", value: 5 },
                      { label: "10件", value: 10 },
                      {
                        label: "全て",
                        value: unattendedLectureData
                          ? unattendedLectureData.length
                          : 0,
                      },
                    ]}
                    count={
                      unattendedLectureData ? unattendedLectureData.length : 0
                    }
                    rowsPerPage={rowsPerPageLectureUnattended}
                    page={pageLectureUnattended}
                    onPageChange={handleChangePageLectureUnattended}
                    onRowsPerPageChange={
                      handleChangeRowsPerPageLectureUnattended
                    }
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </DialogContent>
      </UnAttendedDialog>
      <UnAttendedDialog
        open={openVideoUnattendedDialog}
        onClose={handleCloseVideoUnattendedDialog}
        aria-labelledby="video-unattended-dialog"
      >
        <DialogTitle
          sx={{
            backgroundColor: "#d7b27a",
            color: "white",
            fontWeight: "bold",
            fontSize: "23px",
          }}
          id="video-video-unattended-dialog-title"
          align="center"
        >
          <PersonIcon
            fontSize="large"
            sx={{ verticalAlign: "middle", mr: 1 }}
          />
          動画未完了者一覧
          <br />
          〜講座名：『{lectureName}』, 動画名：『{lectureVideoName}』〜
        </DialogTitle>
        <IconButton
          aria-label="close-video-unattended-dialog"
          onClick={handleCloseVideoUnattendedDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <TableContainer
            component={Paper}
            variant="outlined"
            sx={{ borderRadius: "20px" }}
          >
            <Table
              sx={{
                minWidth: 300,
                [`& .${tableCellClasses.root}`]: {
                  borderLeft: "none",
                  borderRight: "none",
                },
              }}
              aria-label="simple table"
            >
              <TableHead sx={{ backgroundColor: "#E5E4E2" }}>
                <TableRow>
                  <TableCell align="center" sx={{ width: "70%" }}>
                    メールアドレス
                  </TableCell>
                  <TableCell align="center" sx={{ width: "15%" }}>
                    動画視聴状況
                  </TableCell>
                  <TableCell align="center" sx={{ width: "15%" }}>
                    回答状況
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {unattendedVideoData ? (
                  unattendedVideoData
                    .slice(
                      pageVideoUnattended * rowsPerPageVideoUnattended,
                      pageVideoUnattended * rowsPerPageVideoUnattended +
                        rowsPerPageVideoUnattended
                    )
                    .map((row: any) => (
                      <TableRow key={row.id}>
                        <TableCell align="center" sx={{ width: "70%" }}>
                          <Typography variant="body2" fontWeight="bold">
                            {row.email}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: "15%" }}>
                          <Typography variant="body2" fontWeight="bold">
                            {row.videoStatus ? "視聴済" : "未視聴"}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" sx={{ width: "15%" }}>
                          <Typography variant="body2" fontWeight="bold">
                            未受験
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={4}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    labelRowsPerPage="表示件数:"
                    rowsPerPageOptions={[
                      { label: "5件", value: 5 },
                      { label: "10件", value: 10 },
                      {
                        label: "全て",
                        value: unattendedVideoData
                          ? unattendedVideoData.length
                          : 0,
                      },
                    ]}
                    count={unattendedVideoData ? unattendedVideoData.length : 0}
                    rowsPerPage={rowsPerPageVideoUnattended}
                    page={pageVideoUnattended}
                    onPageChange={handleChangePageVideoUnattended}
                    onRowsPerPageChange={handleChangeRowsPerPageVideoUnattended}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </DialogContent>
      </UnAttendedDialog>
      <VideoDialog
        open={openVideoDialog}
        onClose={handleCloseVideoDialog}
        aria-labelledby="video-dialog"
      >
        <DialogTitle
          sx={{
            backgroundColor: "#d7b27a",
            color: "white",
            fontWeight: "bold",
            fontSize: "23px",
          }}
          id="video-dialog-title"
          align="center"
        >
          {content}
        </DialogTitle>
        <IconButton
          aria-label="close-video-dialog"
          onClick={handleCloseVideoDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <div className="player-wrapper">
            <ReactPlayer
              url={videoUrl!}
              className="react-player"
              controls
              width="100%"
              height="100%"
              onError={(e) => onErrorTime(e.target.error)}
            />
          </div>
        </DialogContent>
      </VideoDialog>
    </Box>
  );
}
