import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect, useCallback } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import ReactPlayer from "react-player";
import "./VideoDialog.css";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Snackbar from "@mui/material/Snackbar";
import TablePagination from "@mui/material/TablePagination";
import { TableFooter } from "@mui/material";
import { LightTooltip } from "../components/LightToolTip";
import { useUserFetch } from "../api/fetch";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "1000px",
    maxHeight: "500px",
    width: "100%",
    height: "100%",
    borderRadius: "20px",
  },
}));

const TestDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "1000px",
    maxHeight: "500px",
    width: "100%",
    height: "100%",
    borderRadius: "20px",
  },
}));

export default function VideoList({ id, lectureName }: any) {
  const tableDefine = [
    { label: "動画名", key: "videoName" },
    { label: "視聴状況", key: "statement" },
    { label: "視聴", key: "watch" },
    { label: "回答状況", key: "testSatement" },
    { label: "小テスト", key: "test" },
  ];

  const { get, post } = useUserFetch();

  //tableの要素
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    get<{
      message: string;
      videos: any[];
    }>(`/api/user/lecture/` + id)
      .then((content) => {
        console.log(content.message);
        setData(content.videos);
      })
      .catch(() => alert("Can't get data from db properly."));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  //buttonの初期設定
  const [content, setContent] = useState<string | null>(null); //videoNameを保持する変数
  const [holdId, setHoldId] = useState<string | null>(null); //Idを保持する変数
  const [url, setUrl] = useState<string | null>(null); //Urlを保持する変数
  const [open, setOpen] = useState(false); //VideoDialogを表示するかいなか
  const [openTest, setOpenTest] = useState(false); //TestDialogを表示するかいなか
  const [testAlert, setTestAlert] = useState(false); //TestAlertを表示するかいなか
  const [text, setText] = useState(""); //TestDialogの回答欄のテキストを保持する変数
  const [answerAlert, setAnswerAlert] = useState(false); //AnswerAlertを表示するかいなか
  const [doneAlert, setDoneAlert] = useState(false); //DoneAlertを表示するかいなか
  const [successAlert, setSuccessAlert] = useState(false); //SuccessAlertを表示するかいなか

  //動画のパスをバックエンドから取得する関数
  const getVideo = (fileName: string) => {
    get<{
      url: string;
    }>(`/api/user/video/` + fileName)
      .then((res) => {
        console.log(res.url);
        setUrl(res.url);
      })
      .catch(() => alert("Can't get Video from db properly."));
  };

  //VideoDialogを表示するかいなかのハンドラ
  const handleClickOpen = (content: string, fileName: string, id: string) => {
    setOpen(true);
    setContent(content);
    setHoldId(id);
    getVideo(fileName);
    setTestAlert(false);
  };

  //VideoDialogを閉じるハンドラ
  const handleClose = () => {
    setOpen(false);
  };

  //TestDialogを表示するかいなかのハンドラ
  const handleClickOpenTest = (
    content: string,
    statement: string,
    id: string
  ) => {
    setTestAlert(false);
    if (statement === "完了") {
      setHoldId(id);
      setOpenTest(true);
    } else {
      setTestAlert(true);
    }
    setContent(content);
  };

  const handleCloseTest = () => {
    setOpenTest(false);
    setText("");
    setAnswerAlert(false);
    setDoneAlert(false);
    setSuccessAlert(false);
  };

  //動画視聴開始記録関数
  const onStartTime = async () => {
    const time = new Date();
    const japanTime = new Date(time.getTime() + 9 * 60 * 60 * 1000);
    const startTime = japanTime.toISOString();
    console.log("Video Started :" + startTime);

    post(`/api/user/video/start/` + holdId, {
      body: JSON.stringify({
        start_time: startTime,
      }),
    }).catch(() => alert("Can't get data from db properly."));
  };

  //動画視聴終了&動画視聴完了状況の記録関数
  const onEndedTime = async () => {
    const time = new Date();
    const japanTime = new Date(time.getTime() + 9 * 60 * 60 * 1000);
    const endTime = japanTime.toISOString();
    console.log("Video Ended :" + endTime);

    post(`/api/user/video/end/` + holdId, {
      body: JSON.stringify({
        end_time: endTime,
        statement: true,
      }),
    }).then(() => {
      refresh();
    });
  };

  //動画視聴エラー記録関数
  const onErrorTime = (error: any) => {
    if (error.code === 2) {
      alert("動画のセッションが切れました。");
      handleClose();
    }
    const time = new Date();
    const japanTime = new Date(time.getTime() + 9 * 60 * 60 * 1000);
    const errorTime = japanTime.toISOString();
    console.log("Error has Occured  :" + errorTime);
    console.log("Error Code  :" + error.code);
    console.log("Error" + error);
  };

  //動画視聴状況を取得
  const [statements, setStatements] = useState<any>({});
  //動画視聴完了日を取得
  const [videoEndDates, setVideoEndDates] = useState<any>({});
  //小テスト回答状況を取得
  const [testStatements, setTestStatements] = useState<any>({});
  //小テスト回答日を取得
  const [testCreatedDates, setTestCreatedDates] = useState<any>({});

  const getStatement = useCallback(
    async (id: string) => {
      try {
        return await get<any>(`/api/user/video/statement/` + id);
      } catch (error) {
        alert("Can't get data from db properly.");
        return [];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const refresh = useCallback(async () => {
    const fetchData = async () => {
      const newStatements: any = {};
      const newVideoEndDates: any = {};
      const newTestStatements: any = {};
      const newTestCreatedDates: any = {};

      for (const row of data) {
        const result = await getStatement(row.id);
        newStatements[row.id] = result.statement ? "完了" : "未完了";
        newVideoEndDates[row.id] =
          result.videoEndTime !== null ? result.videoEndTime : "-";
        newTestStatements[row.id] =
          result.testStatement !== null ? "受験済" : "未受験";
        newTestCreatedDates[row.id] =
          result.testStatement !== null ? result.testStatement : "-";
      }

      setStatements((prevStatements: any) => ({
        ...prevStatements,
        ...newStatements,
      }));

      setVideoEndDates((prevVideoEndDates: any) => ({
        ...prevVideoEndDates,
        ...newVideoEndDates,
      }));

      setTestStatements((prevTestStatements: any) => ({
        ...prevTestStatements,
        ...newTestStatements,
      }));

      setTestCreatedDates((prevTestCreatedDates: any) => ({
        ...prevTestCreatedDates,
        ...newTestCreatedDates,
      }));
    };
    fetchData();
  }, [data, getStatement]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  //小テスト結果を送信する関数
  const submitTestResult = async (id: string, answer: any) => {
    try {
      if (answer.length > 200) {
        setAnswerAlert(true);
        return;
      }

      const time = new Date();
      const japanTime = new Date(time.getTime() + 9 * 60 * 60 * 1000);
      const createdAt = japanTime.toISOString();
      console.log("Test created at :" + createdAt);

      post<any>(`/api/user/video/test/` + id, {
        body: JSON.stringify({
          answer: answer,
          created_at: createdAt,
        }),
      }).then((res) => {
        if (res.status === "uvt1") {
          console.log(res.message);
          setSuccessAlert(true);
          refresh();
        } else if (res.status === "uvt4") {
          console.log(res.message);
          setDoneAlert(true);
        } else {
          console.log(res.status);
          console.log(res.message);
        }
      });
    } catch (error) {
      alert(`Error submitting test result: ${error}`);
    }
  };

  function formatDate(dateString: any) {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
    const day = ("0" + date.getUTCDate()).slice(-2);

    return `${year}/${month}/${day}`;
  }

  //tableのtbody部分
  const tbody = data ? (
    data.map((row: any) => {
      const statement = statements[row.id];
      const videoEndDate = videoEndDates[row.id];
      const testStatement = testStatements[row.id];
      const testCreatedDate = testCreatedDates[row.id];
      return (
        <TableRow key={row.id}>
          <TableCell
            component="th"
            scope="row"
            align="center"
            sx={{ fontWeight: "bold" }}
          >
            {row.videoName}
          </TableCell>
          <TableCell align="center">
            <LightTooltip
              title={
                <React.Fragment>
                  視聴日:{" "}
                  {videoEndDate !== "-" ? formatDate(videoEndDate) : "-"}
                </React.Fragment>
              }
              placement="right"
            >
              <span>{statement}</span>
            </LightTooltip>
          </TableCell>
          <TableCell align="center">
            <IconButton
              aria-label="play"
              size="large"
              onClick={() =>
                handleClickOpen(row.videoName, row.fileName, row.id)
              }
            >
              <PlayCircleIcon fontSize="medium" />
            </IconButton>
          </TableCell>
          <TableCell align="center">
            <LightTooltip
              title={
                <React.Fragment>
                  回答日:{" "}
                  {testCreatedDate !== "-" ? formatDate(testCreatedDate) : "-"}
                </React.Fragment>
              }
              placement="right"
            >
              <span>{testStatement}</span>
            </LightTooltip>
          </TableCell>
          <TableCell align="center">
            <Button
              variant="contained"
              onClick={() =>
                handleClickOpenTest(row.videoName, statement, row.id)
              }
              sx={{
                borderRadius: "20px",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
            >
              回答する
            </Button>
          </TableCell>
        </TableRow>
      );
    })
  ) : (
    <TableRow>
      <TableCell align="center" colSpan={4}>
        動画がありません
      </TableCell>
    </TableRow>
  );

  //小テストの回答欄の変更を検知する関数
  const handleTextChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setText(event.target.value);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 800, bgcolor: "background.paper" }}>
      <Typography
        sx={{ mt: 4, mb: 2, mr: 4, ml: 4, fontWeight: "bold" }}
        variant="h4"
        component="div"
      >
        {lectureName}
      </Typography>
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ mr: 4, ml: 4, borderRadius: "20px" }}
      >
        <Table
          sx={{
            minWidth: 300,
            [`& .${tableCellClasses.root}`]: {
              borderLeft: "none",
              borderRight: "none",
            },
          }}
          aria-label="simple table"
        >
          <TableHead sx={{ backgroundColor: "#E5E4E2" }}>
            <TableRow>
              {tableDefine.map((def) => (
                <TableCell key={def.key} align="center">
                  {def.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{tbody}</TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                labelRowsPerPage="表示件数:"
                rowsPerPageOptions={[
                  { label: "5件", value: 5 },
                  { label: "10件", value: 10 },
                  { label: "50件", value: 50 },
                  { label: "全て", value: data ? data.length : 0 },
                ]}
                count={data ? data.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Card
        variant="outlined"
        sx={{
          mt: 2,
          mb: 2,
          mr: 4,
          ml: 4,
          width: "100%",
          maxWidth: 1000,
          backgroundColor: "#E5E4E2",
          borderRadius: "20px",
        }}
      >
        <CardContent>
          <Typography sx={{ mb: 2 }} variant="h5" component="div">
            【注意】
          </Typography>
          <Typography sx={{ mb: 2 }} component="div">
            ・上の動画から順に視聴してください。
            <br />
            ・動画を見終わらないと小テストは受験できません。
          </Typography>
        </CardContent>
      </Card>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="video-dialog"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="video-dialog" align="center">
          {content}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              width="100%"
              height="100%"
              url={url!}
              controls
              onStart={onStartTime}
              onEnded={onEndedTime}
              onError={(e) => onErrorTime(e.target.error)}
            />
          </div>
        </DialogContent>
      </BootstrapDialog>
      <TestDialog
        onClose={handleCloseTest}
        aria-labelledby="test-dialog"
        open={openTest}
        PaperProps={{
          component: "form",
          onSubmit: async (event: {
            preventDefault: () => void;
            currentTarget: HTMLFormElement | undefined;
          }) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const text = formJson.answer;
            await submitTestResult(holdId!, text);
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, fontWeight: "bold" }}
          id="test-dialog"
          align="center"
        >
          {content + "の小テスト"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseTest}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <TextField
            autoFocus
            id="test-answer"
            name="answer"
            label={
              "ここに動画を見た上で抱いた感想を記述してください(200文字以内)"
            }
            required
            multiline
            fullWidth
            rows={14}
            onChange={handleTextChange}
            error={text.length > 200}
            helperText={text.length > 200 ? "200文字を超えています" : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            sx={{
              borderRadius: "20px",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
          >
            提出する
          </Button>
        </DialogActions>
      </TestDialog>
      <Snackbar open={testAlert}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setTestAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mr: 4, ml: 4 }}
        >
          <AlertTitle>小テスト受験不可</AlertTitle>
          動画視聴を完了してください。
        </Alert>
      </Snackbar>
      <Snackbar open={answerAlert}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAnswerAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mr: 4, ml: 4 }}
        >
          <AlertTitle>小テスト提出不可</AlertTitle>
          200文字以内で回答してください。
        </Alert>
      </Snackbar>
      <Snackbar open={doneAlert}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setDoneAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mr: 4, ml: 4 }}
        >
          <AlertTitle>小テスト提出不可</AlertTitle>
          すでにあなたの回答は提出済みです。
        </Alert>
      </Snackbar>
      <Snackbar open={successAlert}>
        <Alert severity="success" sx={{ mr: 4, ml: 4 }}>
          小テストが正常に提出されました。
        </Alert>
      </Snackbar>
    </Box>
  );
}
