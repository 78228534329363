import { useContext } from "react";

import { Button } from "@mui/material";

import logout from "../api/logout";
import { BaseContext } from "..";

export default function LogoutButton() {
  const setSession = useContext(BaseContext);

  const handleLogOut = async () => {
    await logout(setSession!);
  };

  return (
    <Button
      onClick={handleLogOut}
      color="inherit"
      variant="outlined"
      sx={{ borderRadius: "20px" }}
    >
      LOGOUT
    </Button>
  );
}
