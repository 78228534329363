import { useCallback } from "react";
import { ErrorInfo } from "../errorInfo";
import { useFetch } from "./fetch";
import { BaseContextType } from "..";

type LoginErrorInfo = ErrorInfo<"email"> | ErrorInfo<"password">;

type LoginResult = {
  message: string;
  status: string;
  token: string;
};

type AdminLoginResult = {
  message: string;
  status: string;
  admin_token: string;
};

export const useUserLogin = (setSession: BaseContextType) => {
  const { post } = useFetch();

  const userLogin = useCallback(
    async (email: string, password: string): Promise<LoginErrorInfo[]> => {
      const errInfo = validate(email, password);
      if (errInfo.length > 0) {
        return errInfo;
      }

      const result = await post<LoginResult>(`/api/login/user`, {
        body: JSON.stringify({
          login_email: email,
          login_password: password,
        }),
      });

      console.log(result.message);
      if (result.status === "lu1") {
        localStorage.setItem("token", result.token);
        setSession({ user: result.token });
        return [];
      }

      //   ["lu2", "lu3"] その他の場合もあるっぽい
      errInfo.push({
        type: "password",
        message: "パスワードが間違っています",
      });

      return errInfo;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSession]
  );

  return userLogin;
};

export const useAdminLogin = (setSession: BaseContextType) => {
  const { post } = useFetch();

  const adminLogin = useCallback(
    async (email: string, password: string) => {
      const errInfo = validate(email, password);
      if (errInfo.length > 0) {
        return errInfo;
      }

      const result = await post<AdminLoginResult>(`/api/login/admin`, {
        body: JSON.stringify({
          login_admin_email: email,
          login_admin_password: password,
        }),
      });

      console.log(result.message);
      if (result.status === "la1") {
        localStorage.setItem("admin_token", result.admin_token);
        setSession({ admin: result.admin_token });
        return [];
      }

      //   ["la2", "la3"] その他の場合もあるっぽい
      errInfo.push({
        type: "password",
        message: "パスワードが間違っています",
      });

      return errInfo;
    },
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSession]
  );

  return adminLogin;
};

const validate = (email: string, password: string) => {
  const errInfo: LoginErrorInfo[] = [];

  if (email === "") {
    errInfo.push({
      type: "email",
      message: "メールアドレスを入力してください",
    });
  }
  if (password === "") {
    errInfo.push({
      type: "password",
      message: "パスワードを入力してください",
    });
  }
  return errInfo;
};
