import Button from "@mui/material/Button";

type LoginButtonProps = {
  onClick: () => void;
  disabled: boolean;
};

export const LoginButton: React.FC<LoginButtonProps> = ({
  disabled,
  onClick,
}) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      sx={{
        width: "50%",
        color: "#c1c1a4",
        alignSelf: "center",
        backgroundColor: "white",
        borderRadius: "16px",
        border: "2px solid #c1c1a4",
        fontSize: "15px",
        "&:hover": {
          transform: "scale(1.1)",
          backgroundColor: "white",
        },
      }}
    >
      ログイン
    </Button>
  );
};