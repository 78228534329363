import React from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

export default function Error404() {
    const navigate = useNavigate();
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <h1>404 not Found</h1>
            <p>このページは存在しません。</p>
            <Button 
                variant="contained"
                sx={{ 
                    mr: 2,  
                    color: "white", 
                    backgroundColor: "#d7b27a", 
                    borderRadius: '20px',
                    '&:hover': {
                        backgroundColor: "#D7B27A", 
                        transform: 'scale(1.025)',
                    },
                    fontWeight: 'bold',
                }}
                onClick={() => {
                    navigate('/');
                }}
            >
                ログイン画面に戻る
            </Button>
        </div>
    );
}