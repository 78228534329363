import React from "react";

import { styled, SxProps, Theme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";

import { AdminLoginForm, UserLoginForm } from "./LoginForm";
import { getBackgroundImage } from "../environment";

const LoginDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "500px",
    maxHeight: "500px",
    width: "100%",
    height: "100%",
    borderRadius: "20px",
  },
}));

type DialogProps = {
  open: boolean;
  onClose: () => void;
  onChangeDialog: () => void;
};

type CustomDialogType = {
  titleSx: SxProps<Theme>;
  buttonSx: SxProps<Theme>;
  contentSx: SxProps<Theme>;
  title: string;
  children: React.ReactNode;
};

export const UserLoginDialog: React.FC<DialogProps> = (props) => {
  return (
    <DialogInternal
      {...props}
      titleSx={{
        color: "white",
      }}
      title="受講者ログイン"
      buttonSx={{
        color: "white",
      }}
      contentSx={{}}
    >
        <UserLoginForm onChangeLogin={props.onChangeDialog} />
    </DialogInternal>
  );
};

export const AdminLoginDialog: React.FC<DialogProps> = (props) => {
  return (
    <DialogInternal
      {...props}
      titleSx={{
        color: "transparent",
        backgroundClip: "text",
      }}
      title="管理者ログイン"
      buttonSx={{
        color: (theme) => theme.palette.grey[500],
      }}
      contentSx={{
        backgroundImage: getBackgroundImage(),
      }}
    >
        <AdminLoginForm onChangeLogin={props.onChangeDialog} />

    </DialogInternal>
  );
};

type DialogInternalProps = DialogProps & CustomDialogType;

const DialogInternal: React.FC<DialogInternalProps> = ({
  buttonSx,
  contentSx,
  titleSx,
  title,
  open,
  onClose,
  children,
}) => {
  return (
    <LoginDialog open={open}>
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          backgroundImage: getBackgroundImage(),
          ...titleSx,
        }}
        align="center"
      >
        {title}
      </DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          ...buttonSx,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        dividers
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          ...contentSx,
        }}
      >
        {children}
      </DialogContent>
    </LoginDialog>
  );
};
