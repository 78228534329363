import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { LoginButton } from "./LoginButton";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useAdminLogin, useUserLogin } from "../api/login";
import { TryLoginMessage } from "./TryLoginMessage";
import { StyledTextField } from "./StyledTextField";
import { BaseContext } from "..";

const useSimpleInput = (initialValue: string) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setError(null);
  }, [value]);

  return {
    value,
    setValue: (value?: string) => setValue(value ?? ""),
    error,
    setError,
  };
};

type UseUserLoginType = ReturnType<typeof useUserLogin>;
type LoginFormProps = {
  onChangeLogin: () => void;
  login: (email: string, password: string) => ReturnType<UseUserLoginType>;
  text: string;
};

const maxLoginAttempts = 5;

export const UserLoginForm: React.FC<Pick<LoginFormProps, "onChangeLogin">> = ({
  onChangeLogin,
}) => {
  const setSession = useContext(BaseContext);
  const userLogin = useUserLogin(setSession!);

  const text = "管理者はこちら";
  return (
    <LoginForm onChangeLogin={onChangeLogin} login={userLogin} text={text} />
  );
};

export const AdminLoginForm: React.FC<
  Pick<LoginFormProps, "onChangeLogin">
> = ({ onChangeLogin }) => {
  const setSession = useContext(BaseContext);
  const adminLogin = useAdminLogin(setSession!);

  const text = "受講者はこちら";
  return (
    <LoginForm onChangeLogin={onChangeLogin} login={adminLogin} text={text} />
  );
};

const LoginForm: React.FC<LoginFormProps> = ({
  onChangeLogin,
  login,
  text,
}) => {
  // 何かhooksありそう
  const email = useSimpleInput("");
  const password = useSimpleInput("");
  const [showPassword, setShowPassword] = useState(false);
  const [attempt, setAttempt] = useState(0);
  const disabled = attempt >= maxLoginAttempts;

  const onLogin = useCallback(async () => {
    const errInfo = await login(email.value, password.value);
    errInfo.forEach((err) => {
      if (err.type === "email") {
        email.setError(err.message);
      } else if (err.type === "password") {
        password.setError(err.message);
      }
    });
    setAttempt((prev) => prev + 1);
  }, [email, password, login]);

  return (
    <>
      <StyledTextField
        label="メールアドレス"
        variant="outlined"
        value={email.value}
        onChange={(e) => {
          email.setValue(e.target.value);
        }}
        required
        error={email.error !== null}
        helperText={email.error}
        disabled={disabled}
        InputProps={{
          style: { backgroundColor: "white" },
        }}
      />
      <StyledTextField
        label="パスワード"
        type={showPassword ? "text" : "password"}
        variant="outlined"
        value={password.value}
        onChange={(e) => {
          password.setValue(e.target.value);
        }}
        required
        error={password.error !== null}
        helperText={password.error}
        InputProps={{
          style: { backgroundColor: "white" },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={() => setShowPassword(!showPassword)}
                // onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? (
                  <VisibilityOff fontSize="small" />
                ) : (
                  <Visibility fontSize="small" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        disabled={disabled}
      />
      <Button
        onClick={onChangeLogin}
        sx={{
          width: "35%",
          alignSelf: "flex-end",
          textDecoration: "underline",
          "&:hover": {
            transform: "scale(1.025)",
          },
        }}
      >
        {text}
      </Button>
      <TryLoginMessage setAttempt={setAttempt} attempt={attempt} />
      <LoginButton disabled={disabled} onClick={onLogin} />
    </>
  );
};
