import styled from "@mui/material/styles/styled";
import FormControl from "@mui/material/FormControl";

export const StyledFormControl = styled(FormControl)({
  "& .MuiOutlinedInput-root": {
    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
  "& .MuiFormHelperText-root": {
    color: "red",
  },
});