import { useNavigate } from "react-router";
import { getBaseURI } from "../environment";

export const useUserFetch = () => {
  const { get: _get, post: _post, put: _put } = useFetch();
  const getToken = useUserToken();

  const get = async <V>(uri: string) => {
    const token = getToken();
    return _get<V>(uri, token);
  };
  const post = async <V>(uri: string, init: RequestInit) => {
    const token = getToken();
    return _post<V>(uri, init, token);
  };

  const put = async <V>(uri: string, init: RequestInit) => {
    const token = getToken();
    return _put<V>(uri, init, token);
  };

  return {
    get,
    post,
    put,
  };
};

const useUserToken = () => {
  const navigate = useNavigate();

  return () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log("No token");
      navigate("/");
      throw new Error("no session");
    }
    return token;
  };
};

export const useAdminFetch = () => {
  const { get: _get, post: _post, put: _put } = useFetch();
  const getToken = useAdminToken();

  const get = async <V>(uri: string) => {
    const token = getToken();
    return _get<V>(uri, token);
  };
  const post = async <V>(uri: string, init: RequestInit) => {
    const token = getToken();
    return _post<V>(uri, init, token);
  };

  const put = async <V>(uri: string, init: RequestInit) => {
    const token = getToken();
    return _put<V>(uri, init, token);
  };

  return {
    get,
    post,
    put,
  };
};

const useAdminToken = () => {
  return () => {
    const token = localStorage.getItem("admin_token");
    if (!token) {
      console.log("No token");
      alert("セッションが切れました。");
      throw new Error("no session");
    }
    return token;
  };
};

export const useFetch = () => {
  const navigate = useNavigate();

  const get = async <V>(uri: string, token?: string): Promise<V> => {
    const result = await getInternal(uri, token);
    if (!result.ok) {
      console.log("Error:" + result.status);
      throw new Error("Error" + result.status);
    }
    checkStatue(result, navigate);

    return result.json();
  };

  const post = async <V>(
    uri: string,
    init: RequestInit,
    token?: string
  ): Promise<V> => {
    const result = await postInternal(uri, init, token);
    if (!result.ok) {
      console.log("Error:" + result.status);
      throw new Error("Error" + result.status);
    }
    checkStatue(result, navigate);

    return result.json();
  };

  const put = async <V>(
    uri: string,
    init: RequestInit,
    token?: string
  ): Promise<V | number> => {
    const result = await putInternal(uri, init, token);
    if (!result.ok) {
      console.log("Error:" + result.status);
      throw new Error("Error" + result.status);
    }
    checkStatue(result, navigate);

    if (result.headers.get("Content-Length") === "0") {
      return result.status;
    }
    return result.json();
  };

  return {
    get,
    post,
    put,
  };
};

const checkStatue = (
  result: Response,
  navigate: ReturnType<typeof useNavigate>
) => {
  if (result.status === 401) {
    alert("セッションが切れました。");
    navigate("/");
  } else if (result.status === 500) {
    navigate("/500");
  } else if (result.status === 404) {
    navigate("/404");
  }
};

/**
 * 露出させたくないけど、Routerで利用する 
 * @param uri 
 * @param token 
 * @returns 
 */
export const getInternal = (uri: string, token?: string) => {
  const url = getURL(uri);
  return fetch(url, {
    method: "get",
    headers: {
      Authorization: `Bearer ${token ?? ""}`,
    },
  });
};

/**
 * 露出させたくないけど、Routerで利用する 
 * @param uri 
 * @param token 
 * @returns 
 */
export const postInternal = async (uri: string, init: RequestInit, token?: string) => {
  const url = getURL(uri);
  return fetch(url, {
    method: "post",
    headers: {
      Authorization: `Bearer ${token ?? ""}`,
      "Content-Type": "application/json",
    },
    ...init,
  });
};

const putInternal = async (uri: string, init: RequestInit, token?: string) => {
  const url = getURL(uri);
  return fetch(url, {
    method: "put",
    headers: {
      Authorization: `Bearer ${token ?? ""}`,
      "Content-Type": "application/json",
    },
    ...init,
  });
};

const getURL = (uri: string) => {
  return uri.indexOf("http") === 0 ? uri : `${getBaseURI()}${uri}`;
}
