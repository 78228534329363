import React from "react";
import { BaseContextType } from ".";

type Props = {
  setSession: BaseContextType;
  children: React.ReactNode;
};

type State = {
  hasError: boolean;
};

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    console.log("error", error);
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    console.log(error, info);
    this.props.setSession({});
  }

  componentDidMount() {
    window.addEventListener("unhandledrejection", this.onUnhandledRejection);
  }

  componentWillUnmount() {
    window.removeEventListener("unhandledrejection", this.onUnhandledRejection);
  }

  onUnhandledRejection = (event: PromiseRejectionEvent) => {
    event.promise.catch((error) => {
      console.log("error", error);
      this.props.setSession({});
    });
  };

  render() {
    if (this.state.hasError) {
      this.props.setSession({});
    }
    return this.props.children;
  }
}
