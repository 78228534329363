import styled from "@mui/material/styles/styled";
import TextField from "@mui/material/TextField";

export const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
  "& .MuiFormHelperText-root": {
    color: "red",
  },
});