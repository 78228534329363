import React, { createContext, useState } from "react";
import ReactDOM from "react-dom/client";
import Router from "./Router";
import { getTitle } from "./environment";
import { ErrorBoundary } from "./ErrorBoundary";

export type SessionType = {
  admin?: string;
  user?: string;
};

export type BaseContextType = React.Dispatch<React.SetStateAction<SessionType>>;

const root = ReactDOM.createRoot(document.getElementById("root")!);
document.title = getTitle();

function Base() {
  const [session, setSession] = useState<SessionType>({
    admin: localStorage.getItem("admin_token") ?? undefined,
    user: localStorage.getItem("token") ?? undefined,
  });

  return (
    <ErrorBoundary setSession={setSession}>
      <BaseContext.Provider value={setSession}>
        <Router session={session} />
      </BaseContext.Provider>
    </ErrorBoundary>
  );
}

export const BaseContext = createContext<BaseContextType | null>(null);

root.render(<Base />);
